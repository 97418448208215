import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { SIGIH_API } from "../app.api";
import { Setor } from "../model/setor.model";
import { Leito } from "../model/leito.model";
import { Investigacao } from "../model/investigacao.model";
import { VinculacaoRequest } from "../model/vinculacao-request.model";

@Injectable()
export class VinculacaoService {

    constructor(private http: HttpClient) { }


    findVinculosByNotificacao(notificaoId:number): Observable<Investigacao[]> {
        let map = new Map<string, string>();


        let params = new HttpParams();
        params = params.append("notificacao", "" + notificaoId);
        

        return this.http.get<Investigacao[]>(`${SIGIH_API}/vinculacaonotificacao/`, {params})
        
    }

    getVinculoByNotificacao(notificaoId:number): Observable<Investigacao> {

        return this.http.get<Investigacao>(`${SIGIH_API}/vinculacaonotificacao/${notificaoId}`)
        
    }

    

    save(request: VinculacaoRequest):Observable<Setor>{
        return this.http.post<Setor>(`${SIGIH_API}/vinculacaonotificacao`, request);
    }   

    
}