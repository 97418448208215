export const environment = {
  production: true,
  api: 'https://ipess.fabricawebsis.com.br/rest/api',
  auth: 'https://ipess.fabricawebsis.com.br/auth/api',
  // api: 'https://www.ipess.saude.df.gov.br/sigih/rest/api',
  // auth: 'https://www.ipess.saude.df.gov.br/sigih/auth',
  clientId: 'sigihweb',
  basicAuthorization: 'Basic c2lnaWh3ZWI6c2VjcmV0',
  csvIntervalMin: 5,
  microServiceFishbone: '/fishbone/',
  versaoEcossistema: '3.7',
  versaoAppWeb: '1.8.5'
};
