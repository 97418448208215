import { Component, OnInit, Input } from '@angular/core';
import { NotificationDetail } from 'src/app/model/notificacao.model';
import { TiposIncidente } from 'src/app/model/tipo-incidente.model';
import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt'
import { EnumTipoDano } from 'src/app/model/tipo-dano.model';
import { EnumCategoriaReacaoMedicamento } from 'src/app/model/categoria-reacao-medicamento.model';
import { DownloadFileService } from 'src/app/service/download-file.service';
import { Arquivo } from 'src/app/model/arquivo.model';

registerLocaleData(localePt);

@Component({
  selector: 'sig-notification-detail',
  templateUrl: './notification-detail.component.html'
})
export class NotificationDetailComponent implements OnInit {

  @Input() notification: NotificationDetail

  outroReacaoAdversa: string
  outroQueixaTecnica: string
  outroIncidenteAdministracao: string
  outroIncidenteDispensacao: string

  constructor(private downloadFileService: DownloadFileService) { }

  ngOnInit() {
  }

  isTipoIncidenteNotificacaoSimplificada(): boolean {
    return this.notification && this.notification.idTipoIncidente === TiposIncidente.NotificacaoSimplificada
  }

  getTipoDano(): string {
    let tipoDano = undefined
    if (this.notification.incidente !== undefined && this.notification.incidente.tipoDano !== undefined) {
      switch (this.notification.incidente.tipoDano) {
        case EnumTipoDano.LEVE:
          tipoDano = 'Leve'
          break
        case EnumTipoDano.MODERADO:
          tipoDano = 'Moderado'
          break
        case EnumTipoDano.GRAVE:
          tipoDano = 'Grave'
          break
        case EnumTipoDano.OBITO:
          tipoDano = 'Óbito'
          break
      }
    }
    return tipoDano
  }

  getReacoesAdversas(): string[] {
    let reacoesAdversas: string[] = []
    if (this.notification !== undefined && this.notification.incidente !== undefined &&
      this.notification.incidente.notificacaoIncidenteFarmacovigilancia !== undefined) {
      const reacoesMedicamento = this.notification.incidente.notificacaoIncidenteFarmacovigilancia.farmacovigilanciaTipoReacaoMedicamento
      for (let reacao of reacoesMedicamento) {
        if (reacao.tipoReacaoMedicamento.categoriaReacaoMedicamento.id === EnumCategoriaReacaoMedicamento.ReacaoAdversa) {
          reacoesAdversas.push(reacao.tipoReacaoMedicamento.nome)
          if (reacao.tipoReacaoMedicamento.notificacaoIncidenteFarmacovigilanciaOutro.length > 0) {
            reacao.tipoReacaoMedicamento.notificacaoIncidenteFarmacovigilanciaOutro.map((outro) => {
              if (outro.id.notificacaoIncidenteFarmacovigilanciaId === this.notification.id &&
                outro.id.tipoReacaoMedicamentoId === reacao.id.tipoReacaoMedicamentoId) {
                this.outroReacaoAdversa = outro.outro
              }
            })
          }
        }
      }
    }
    return reacoesAdversas
  }

  getQueixasTecnicas(): string[] {
    let queixasTecnicas: string[] = []
    if (this.notification !== undefined && this.notification.incidente !== undefined &&
      this.notification.incidente.notificacaoIncidenteFarmacovigilancia !== undefined) {
      const reacoesMedicamento = this.notification.incidente.notificacaoIncidenteFarmacovigilancia.farmacovigilanciaTipoReacaoMedicamento
      for (let reacao of reacoesMedicamento) {
        if (reacao.tipoReacaoMedicamento.categoriaReacaoMedicamento.id === EnumCategoriaReacaoMedicamento.QueixaTecnica) {
          queixasTecnicas.push(reacao.tipoReacaoMedicamento.nome)
          if (reacao.tipoReacaoMedicamento.notificacaoIncidenteFarmacovigilanciaOutro.length > 0) {
            reacao.tipoReacaoMedicamento.notificacaoIncidenteFarmacovigilanciaOutro.map((outro) => {
              if (outro.id.notificacaoIncidenteFarmacovigilanciaId === this.notification.id &&
                outro.id.tipoReacaoMedicamentoId === reacao.id.tipoReacaoMedicamentoId) {
                this.outroQueixaTecnica = outro.outro
              }
            })
          }
        }
      }
    }
    return queixasTecnicas
  }

  getIncidentesAdministracao(): string[] {
    let incidentes: string[] = []
    if (this.notification !== undefined && this.notification.incidente !== undefined &&
      this.notification.incidente.notificacaoIncidenteFarmacovigilancia !== undefined) {
      const reacoesMedicamento = this.notification.incidente.notificacaoIncidenteFarmacovigilancia.farmacovigilanciaTipoReacaoMedicamento
      for (let reacao of reacoesMedicamento) {
        if (reacao.tipoReacaoMedicamento.categoriaReacaoMedicamento.id === EnumCategoriaReacaoMedicamento.IncidenteAdministracao) {
          incidentes.push(reacao.tipoReacaoMedicamento.nome)
          if (reacao.tipoReacaoMedicamento.notificacaoIncidenteFarmacovigilanciaOutro.length > 0) {
            reacao.tipoReacaoMedicamento.notificacaoIncidenteFarmacovigilanciaOutro.map((outro) => {
              if (outro.id.notificacaoIncidenteFarmacovigilanciaId === this.notification.id &&
                outro.id.tipoReacaoMedicamentoId === reacao.id.tipoReacaoMedicamentoId) {
                this.outroIncidenteAdministracao = outro.outro
              }
            })
          }
        }
      }
    }
    return incidentes
  }

  getIncidentesDispensacao(): string[] {
    let incidentes: string[] = []
    if (this.notification !== undefined && this.notification.incidente !== undefined &&
      this.notification.incidente.notificacaoIncidenteFarmacovigilancia !== undefined) {
      const reacoesMedicamento = this.notification.incidente.notificacaoIncidenteFarmacovigilancia.farmacovigilanciaTipoReacaoMedicamento
      for (let reacao of reacoesMedicamento) {
        if (reacao.tipoReacaoMedicamento.categoriaReacaoMedicamento.id === EnumCategoriaReacaoMedicamento.IncidenteDispensacao) {
          incidentes.push(reacao.tipoReacaoMedicamento.nome)
          if (reacao.tipoReacaoMedicamento.notificacaoIncidenteFarmacovigilanciaOutro.length > 0) {
            reacao.tipoReacaoMedicamento.notificacaoIncidenteFarmacovigilanciaOutro.map((outro) => {
              if (outro.id.notificacaoIncidenteFarmacovigilanciaId === this.notification.id &&
                outro.id.tipoReacaoMedicamentoId === reacao.id.tipoReacaoMedicamentoId) {
                this.outroIncidenteDispensacao = outro.outro
              }
            })
          }
        }
      }
    }
    return incidentes
  }

  downloadArquivo(arquivo: Arquivo): void {
    this.downloadFileService.downloadFile(arquivo.id, arquivo.nome)
  }

  getDataValidade() {
    if(this.notification.incidente.notificacaoIncidenteFarmacovigilancia && this.notification.incidente.notificacaoIncidenteFarmacovigilancia.dataValidade)
      return this.notification.incidente.notificacaoIncidenteFarmacovigilancia.dataValidade.substring(0, 2) + '/' + this.notification.incidente.notificacaoIncidenteFarmacovigilancia.dataValidade.substring(2, 6)
  }

}
