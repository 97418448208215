import { CanLoad, Route, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate } from "@angular/router";
import { Injectable } from "@angular/core";
import { LoginService } from "../service/login.service";

@Injectable()
export class LoggedInGuard implements CanLoad, CanActivate {
    
    constructor(private loginService: LoginService) {}
    
    canLoad(route: Route): boolean {
        return this.checkAuthentication()
    }

    canActivate(activatedRoute: ActivatedRouteSnapshot, routerState: RouterStateSnapshot): boolean {
        return this.checkAuthentication()
    }

    checkAuthentication(): boolean {
        const loggedIn = this.loginService.isLoggedIn()
        if(!loggedIn) {
            this.loginService.handleLogin()
        }
        return loggedIn
    }
    
}