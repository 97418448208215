import { RouteAnaliseGuard } from './security/route.analise.guard';
import { Routes } from '@angular/router'
import { HomeComponent } from './view/home/home.component';
import { NotFoundComponent } from './view/not-found/not-found.component';
import { LoggedInGuard } from './security/loggedin.guard';
import { RouteCrudAdminGuard } from './security/route.crud.admin.guard';
import { RouteInvestigadorGuard } from './security/route.investigador.guard';
import { RouteCrudGestaoGuard } from './security/route.crud.gestao.guard';

const ROUTES: Routes = [
    { path: '', component: HomeComponent },
    { path: 'notificacao', loadChildren: './module/notificacao.module#NotificacaoModule' },
    { path: 'gerir', loadChildren: './module/gerir.module#GerirModule', canLoad: [LoggedInGuard], canActivate: [LoggedInGuard] },
    { path: 'perfil', loadChildren: './module/perfil.module#PerfilModule', canActivate: [LoggedInGuard] },
    { path: 'exportar', loadChildren: './module/export.module#ExportModule', canActivate: [RouteAnaliseGuard] },
    { path: 'equipamentos', loadChildren: './module/equipamento.module#EquipamentosModule', canActivate: [RouteCrudGestaoGuard]},
    { path: 'indicadores', loadChildren: './module/indicadores.module#IndicadoresModule', canActivate: [RouteAnaliseGuard] },
    { path: 'investigacao', loadChildren: './module/investigacao.module#InvestigacaoModule',canActivate: [RouteInvestigadorGuard]},
    { path: 'materiais-medicos', loadChildren: './module/material-medico-hospitalar.module#MaterialMedicoHospitalarModule', canActivate: [RouteCrudGestaoGuard]},
    { path: 'vinculacao', loadChildren: './module/vinculacao.module#VinculacaoModule',canActivate: [LoggedInGuard]},
    { path: 'usuarios', loadChildren: './module/usuarios.module#UsuariosModule',canActivate: [RouteCrudGestaoGuard]},
    { path: 'setores', loadChildren: './module/setores.module#SetoresModule', canActivate: [RouteCrudGestaoGuard]},
    { path: 'regioes', loadChildren: './module/regioes.module#RegioesModule',canActivate: [RouteCrudGestaoGuard]},
    { path: 'unidade-saude', loadChildren: './module/unidade-saude.module#UnidadeSaudeModule', canActivate: [RouteCrudGestaoGuard]},
    { path: '**', component: NotFoundComponent }

    // -------------------------------- Dev --------------------------------
    // { path: 'gerir', loadChildren: './module/gerir.module#GerirModule' },
    // { path: 'perfil', loadChildren: './module/perfil.module#PerfilModule' },
    // { path: 'exportar', loadChildren: './module/export.module#ExportModule' },
    // { path: 'equipamentos', loadChildren: './module/equipamento.module#EquipamentosModule'},
    // { path: 'indicadores', loadChildren: './module/indicadores.module#IndicadoresModule' },
    // { path: 'investigacao', loadChildren: './module/investigacao.module#InvestigacaoModule'},
    // { path: 'materiais-medicos', loadChildren: './module/material-medico-hospitalar.module#MaterialMedicoHospitalarModule'},
    // { path: 'vinculacao', loadChildren: './module/vinculacao.module#VinculacaoModule'},
    // { path: 'usuarios', loadChildren: './module/usuarios.module#UsuariosModule'},
    // { path: 'setores', loadChildren: './module/setores.module#SetoresModule'},
    // { path: 'regioes', loadChildren: './module/regioes.module#RegioesModule'},
    // { path: 'unidade-saude', loadChildren: './module/unidade-saude.module#UnidadeSaudeModule'},
    // { path: '**', component: NotFoundComponent }
]

enum EnumRoute {
    HOME = '/',
    SIGNIN = '/signin',
    SIGNUP = '/signup',
    NEW_PASSWORD = '/new-password',
    PERFIL = '/perfil',
    GERIR = '/gerir',
    NOTIFICACAO = '/notificacao',
    NOTIFICACAO_PASSO_1 = '/notificacao/passo-um',
    NOTIFICACAO_PASSO_2 = '/notificacao/passo-dois',
    NOTIFICACAO_PASSO_3 = '/notificacao/passo-tres',
    NOTIFICACAO_PASSO_4 = '/notificacao/passo-quatro',
    DETALHE_NOTIFICACAO = '/notificacao/:id',
    EXPORTAR = '/exportar',
    EQUIPAMENTOS = '/equipamentos',
    MATERIAIS_MEDICOS = '/materiais-medicos',
    INDICADORES = '/indicadores',
    INVESTIGAR_PASSO_3 = '/investigar/passo-tres',
    INVESTIGAR_PASSO_4 = '/investigar/passo-quatro',
    USUARIOS = '/usuarios',
    SETORES = '/setores',
    VINCULACAO = '/vinculacao'
}

export { ROUTES, EnumRoute }
