import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Signin } from "../model/signin.model";
import { Observable, Observer, Subscription, Subscriber, Subject } from "rxjs";
import { SIGIH_AUTH } from "../app.api";
import { tap, map } from "rxjs/operators";
import { JwtHelperService } from "@auth0/angular-jwt";
import { Token } from "../model/token.model";
import { Usuario, EnumPerfilUsuario } from "../model/usuario.model";
import { Router } from "@angular/router";
import { environment } from "src/environments/environment";
import { UserIdleService } from "angular-user-idle";
import { PerfilAcessoUsuario } from "../model/perfil-acesso-usuario.model";
import { Hospital } from "../model/hospital.model";
import { Perfil } from "../model/perfil.model";

@Injectable()
export class LoginService {
  helper = new JwtHelperService();
  usuario: Usuario;
  startedIdleUser: boolean;
  pingSubscription: Subscription;
  timeoutSubscription: Subscription;
  private refreshTokenExipred$ = new Subject<boolean>();

  constructor(
    private http: HttpClient,
    private router: Router,
    private userIdle: UserIdleService
  ) {
    this.timeoutSubscription = this.userIdle.onTimeout().subscribe(() => {
      this.destroyUserLogged();
    });

    if (this.isLoggedIn()) {
      this.registerIdleUser();
    }
  }

  private createHttpOptions(ctxPerfilId: number, ctxPerfilHospitalId?: number) {
    if (ctxPerfilId && ctxPerfilHospitalId) {
      return {
        headers: new HttpHeaders({
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: environment.basicAuthorization,
          "CTX-IPESS-PERFIL": "" + ctxPerfilId,
          "CTX-IPESS-HOSPITAL": "" + ctxPerfilHospitalId,
        }),
      };
    } else if (ctxPerfilId && !ctxPerfilHospitalId) {
      return {
        headers: new HttpHeaders({
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: environment.basicAuthorization,
          "CTX-IPESS-PERFIL": "" + ctxPerfilId,
        }),
      };
    } else {
      return {
        headers: new HttpHeaders({
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: environment.basicAuthorization,
          "CTX-IPESS-PERFIL-DEFAULT": "true",
        }),
      };
    }
  }

  onRefreshTokenExpired(): Observable<boolean> {
    return this.refreshTokenExipred$.pipe(
      tap(() => {
        this.breakIdleUser();
        this.destroyUserLogged();
      }),
      map(() => true)
    );
  }

  onUserTimeOut(): Observable<boolean> {
    return this.userIdle.onTimeout();
  }

  onTimeoutCounter(): Observable<number> {
    let obs = new Observable<number>();
    return this.userIdle.onTimerStart();
  }

  getTimeoutLimit(): number {
    return this.userIdle.getConfigValue().timeout;
  }

  stopCountTimeout() {
    this.userIdle.stopTimer();
  }

  login(signin: Signin): Observable<Token> {
    let body = new URLSearchParams();
    body.set("client_id", signin.client_id);
    body.set("grant_type", signin.grant_type);
    body.set("username", signin.username);
    body.set("password", signin.password);

    return this.http
      .post<Token>(
        `${SIGIH_AUTH}/oauth/token`,
        body.toString(),
        this.createHttpOptions(null)
      )
      .pipe(
        tap((token) => {
          this.usuario = this.setUsuario(token);
          if (this.usuario)
            localStorage.setItem("jwt", JSON.stringify(this.usuario.token));
          this.registerIdleUser();
        })
      );
  }

  private registerIdleUser() {
    this.userIdle.startWatching();
    this.pingSubscription = this.userIdle.ping$.subscribe(() => {
      console.log("Start get new token by ping: ", new Date());
      if (this.usuario) {
        this.refreshToken().subscribe(
          () => {},
          (e) => {
            this.refreshTokenExipred$.next(true);
          }
        );
      }
    });
  }

  refreshToken(): Observable<Token> {
    let idPerfil = null;
    let idHospital = null;
    if (
      this.usuario.perfilAcessoUsuarioCtx &&
      this.usuario.perfilAcessoUsuarioCtx.perfil
    ) {
      idPerfil = this.usuario.perfilAcessoUsuarioCtx.perfil.id;
    }
    if (
      this.usuario.perfilAcessoUsuarioCtx &&
      this.usuario.perfilAcessoUsuarioCtx.hospital
    ) {
      idHospital = this.usuario.perfilAcessoUsuarioCtx.hospital.id;
    }

    return this.refreshTokenRequest(idPerfil, idHospital);
  }

  refreshTokenRequest(idPerfil: number, idHospital: number): Observable<Token> {
    console.log("new refreshToken");
    let body = new URLSearchParams();
    body.set("grant_type", "refresh_token");
    body.set("refresh_token", this.usuario.token.refresh_token);

    return this.http
      .post<Token>(
        `${SIGIH_AUTH}/oauth/token`,
        body.toString(),
        this.createHttpOptions(idPerfil, idHospital)
      )
      .pipe(
        tap((token) => {
          console.log("get new token done");
          this.usuario = this.setUsuario(token);
          if (this.usuario) {
            localStorage.setItem("jwt", JSON.stringify(this.usuario.token));
          }
        })
      );
  }

  private destroyUserLogged() {
    this.usuario = undefined;
    localStorage.removeItem("jwt");
  }

  private breakIdleUser() {
    this.pingSubscription.unsubscribe();
    this.timeoutSubscription.unsubscribe();
    this.userIdle.stopWatching();
  }

  logout() {
    console.log("realizando logout");
    this.destroyUserLogged();
    this.breakIdleUser();
    this.router.navigate(["/signin"]);
  }

  isLoggedIn(): boolean {
    if (!this.usuario || !this.usuario.token) {
      var jwt: Token = JSON.parse(localStorage.getItem("jwt"));
      this.usuario = this.setUsuario(jwt);
    }
    if (!this.usuario || !this.usuario.token) {
      return false;
    }

    if (this.helper.isTokenExpired(this.usuario.token.access_token)) {
      localStorage.removeItem("jwt");
      //window.location.href = window.location.pathname;
      return false;
    } else {
      return true;
    }
  }

  handleLogin() {
    this.router.navigate(["/signin"]);
  }

  private setUsuario(token: Token): Usuario {
    if (!token) {
      return undefined;
    }

    if (this.helper.isTokenExpired(token.access_token)) {
      return undefined;
    }

    const user: any = this.helper.decodeToken(token.access_token);
    let usuario: Usuario = new Usuario();
    usuario.login = user.user_name;
    usuario.idCategoriaProfissional = user.user_detail.idCategoriaProfissional;
    usuario.nome = user.user_detail.nome;
    usuario.matricula = user.user_detail.matricula;
    usuario.email = user.user_detail.email;
    usuario.telefone = user.user_detail.telefone;
    usuario.idHospital = user.user_detail.hospital;
    usuario.usuarioSES = user.user_detail.usuarioSES;
    usuario.profissionalAreaSaude =
      user.user_detail.profissionalSaude.idCategoriaProfissional !== 0
        ? true
        : false;
    usuario.idCategoriaProfissional =
      user.user_detail.profissionalSaude.idCategoriaProfissional;
    usuario.idSetor = user.user_detail.profissionalSaude.idSetor;
    usuario.token = token;
    usuario.perfisAcessoUsuario = new Array();
    usuario.perfilAcessoUsuarioCtx = null;

    if (user.user_detail.perfis) {
      user.user_detail.perfis.forEach((p) => {
        usuario.perfisAcessoUsuario.push(this.createPerfilAcesso(p));
      });
    }

    if (user.ctx_perfil) {
      let pfCtx = this.createPerfilAcesso(user.ctx_perfil);
      usuario.perfilAcessoUsuarioCtx = pfCtx;
    }

    /** Disabled 1.6.0: Features Multiplos Perfis
        if (!usuario.perfilUsuario) {
            switch (user.authorities[0]) {
                case 'GESTOR_SISTEMA_ADMINISTRADOR_GERAL':
                    usuario.perfilUsuario = EnumPerfilUsuario.GESTOR_SISTEMA
                    break;
                case 'GESTOR_HOSPITAL_ADMINISTRADOR_LOCAL':
                    usuario.perfilUsuario = EnumPerfilUsuario.GESTOR_HOSPITAL
                    break;
                case 'GESTOR_NCLEO':
                    usuario.perfilUsuario = EnumPerfilUsuario.GESTOR_NUCLEO
                    break;
                case 'INVESTIGADOR':
                    usuario.perfilUsuario = EnumPerfilUsuario.INVESTIGADOR
                    break;
                case 'INVESTIGADOR_AUXILIAR':
                    usuario.perfilUsuario = EnumPerfilUsuario.INVESTIGADOR_AUXILIAR
                    break;
                case 'NOTIFICADOR':
                    usuario.perfilUsuario = EnumPerfilUsuario.NOTIFICADOR
                    break;
            }
        }**/

    return usuario;
  }

  private createPerfilAcesso(usPerfilToken: any) {
    let pf = new PerfilAcessoUsuario();

    if (usPerfilToken.idHospital) {
      pf.hospital = new Hospital();
      pf.hospital.id = usPerfilToken.idHospital;
      pf.hospital.nome = usPerfilToken.hospital;
    }

    pf.perfil = new Perfil();
    pf.perfil.id = usPerfilToken.idPerfil;
    pf.perfil.nome = usPerfilToken.perfil;
    pf.perfil.grantPerfil = this.translateGrantPerfil(
      usPerfilToken.grantPerfil
    );
    pf.perfil.grantPerfilName = usPerfilToken.grantPerfil;

    return pf;
  }

  private translateGrantPerfil(grant: String) {
    switch (grant) {
      case "GESTOR_SISTEMA_ADMINISTRADOR_GERAL":
        return EnumPerfilUsuario.GESTOR_SISTEMA;
        break;
      case "GESTOR_HOSPITAL_ADMINISTRADOR_LOCAL":
        return EnumPerfilUsuario.GESTOR_HOSPITAL;
        break;
      case "GESTOR_NCLEO":
        return EnumPerfilUsuario.GESTOR_NUCLEO;
        break;
      case "INVESTIGADOR":
        return EnumPerfilUsuario.INVESTIGADOR;
        break;
      case "INVESTIGADOR_AUXILIAR":
        return EnumPerfilUsuario.INVESTIGADOR_AUXILIAR;
        break;
      case "NOTIFICADOR":
        return EnumPerfilUsuario.NOTIFICADOR;
        break;
      case "GESTOR_SEGURANCA_PACIENTE":
        return EnumPerfilUsuario.GESTOR_SEGURANCA_PACIENTE;
        break;
    }
  }
}
