import { Token } from "./token.model";
import { PerfilAcessoUsuario } from "./perfil-acesso-usuario.model";
import { Hospital } from "./hospital.model";

class Usuario {

    constructor() { }

    public id?: string
    public nome: string
    public email: string
    public telefone: string
    //public perfilUsuario: string
    public senha?: string
    public senhaAtual?: string
    public confirmaSenha?: string
    public profissionalAreaSaude?: boolean
    public idCategoriaProfissional?: number
    public idHospital?: number
    public idSetor?: number
    public login?: string
    public usuarioSES?: boolean
    public matricula?: string
    public token?: Token
    public perfisAcessoUsuario?: PerfilAcessoUsuario[]
    public perfilAcessoUsuarioCtx?: PerfilAcessoUsuario
    public perfisAcesso?: EnumPerfilUsuario
    public ativo: boolean
}

interface ProfissionalSaude {
    idCategoriaProfissional: number,
    categoriaProfissional: string,
    idHospital: number,
    cnes: string,
    hospital: string,
    idSetor: number,
    setor: string
}

enum EnumPerfilUsuario {
    GESTOR_SISTEMA = '1',
    GESTOR_HOSPITAL = '2',
    GESTOR_NUCLEO = '3',
    INVESTIGADOR = '4',
    INVESTIGADOR_AUXILIAR = '5',
    NOTIFICADOR = '6',
    GESTOR_SEGURANCA_PACIENTE = '7'
}

export { Usuario, EnumPerfilUsuario }
