import { environment } from 'src/environments/environment'

export class Signin {
    
    constructor(public username: string, public password: string) {
        this.client_id = environment.clientId
        this.grant_type = 'password'
    }

    public client_id: string
    public grant_type: string
    
}
