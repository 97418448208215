import { Injectable } from "@angular/core";
import { shallowEqualArrays } from "@angular/router/src/utils/collection";
import { Observable } from "rxjs";
import { Hospital } from "../model/hospital.model";
import { PerfilAcessoUsuario } from "../model/perfil-acesso-usuario.model";
import { Perfil } from "../model/perfil.model";
import { EnumPerfilUsuario } from "../model/usuario.model";
import { ComboService } from "./combo.service";
import { LoginService } from "./login.service";
import { UnidadeSaude } from "../model/unidade-saude.model";

@Injectable()
export class AuthorizeService {

    constructor(
        private comboService: ComboService,
        private loginService: LoginService) {
    }


    getMyHospitals():Hospital[]{
        let perfis = this.loginService.usuario.perfisAcessoUsuario;
        if(!perfis){
            return new Array();
        }

        let hospitais = new Array();
        perfis
            .filter(p => p.hospital)
            .filter(p => p.hospital.nome)
            .forEach(p => {

                let idx = hospitais.findIndex(h => h.id == p.hospital.id);

                if(idx == -1){
                    hospitais.push(p.hospital);
                }
            })


       return hospitais;
    }

    getHospitalsByCtxAuthorization():Observable<UnidadeSaude[]>{
        let perfis = this.loginService.usuario.perfisAcessoUsuario;

        if(this.hasPerfil(EnumPerfilUsuario.GESTOR_SISTEMA) || this.hasPerfil(EnumPerfilUsuario.GESTOR_SEGURANCA_PACIENTE)){
            return this.comboService.getHospitais();
        }

        return Observable.create(observer => {
            let hosp = new Array();
            hosp.push(this.getHospitalCtx())
            observer.next(hosp);
            //call complete if you want to close this stream (like a promise)
            observer.complete();
        });
    }

    getHospitalCtx():Hospital{
        if(this.loginService.usuario.perfilAcessoUsuarioCtx){
            return this.loginService.usuario.perfilAcessoUsuarioCtx.hospital;
        }

        return null;
    }

    getPerfilCtx():Perfil{
        if(this.loginService.usuario.perfilAcessoUsuarioCtx){
            return this.loginService.usuario.perfilAcessoUsuarioCtx.perfil;
        }

        return null;
    }

    hasPerfil(perfil:EnumPerfilUsuario):boolean {
        let perfilCtx = undefined;

        if(this.loginService.usuario && this.loginService.usuario.perfilAcessoUsuarioCtx && this.loginService.usuario.perfilAcessoUsuarioCtx.perfil){
            perfilCtx = this.loginService.usuario.perfilAcessoUsuarioCtx.perfil.grantPerfil;
        }

        return perfilCtx == perfil;
    }

    hasHospital(idHospital: number):boolean{
        return this.getMyHospitals().findIndex(h => h.id == idHospital) >= 0 ;
    }
}
