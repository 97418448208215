import { Directive, Renderer2, ElementRef, OnInit } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { EnumRoute } from '../app.routes';

@Directive({
  selector: '[sigBodyId]'
})
export class ChangeBodyIdDirective implements OnInit {

  constructor(private renderer: Renderer2, private router: Router, private el: ElementRef) {
  }

  ngOnInit(): void {
    this.router.events.subscribe((navigation: any) => {
      if (navigation instanceof NavigationStart) {
        const url = navigation.url
        if (url.indexOf('signin') >= 0 || url.indexOf('signup') >= 0 || url.indexOf('recover-password') >= 0 ||
          url.indexOf('new-password') >= 0 || url.indexOf('invalid-token') >= 0) {
          this.renderer.setAttribute(this.el.nativeElement, 'id', 'home');
          this.renderer.removeClass(this.el.nativeElement, 'edit');
        } else if (url.indexOf('setores') >= 0 || url.indexOf('usuarios') >= 0 || url.indexOf('perfil') >= 0 || url.indexOf('gerir') >= 0 || url.indexOf('detail') >= 0 ||
          url.indexOf('exportar') >= 0 || url.indexOf('vinculacao') >= 0 || url.indexOf('investigacao') >= 0 || url.indexOf('indicadores') >= 0 || url.indexOf('equipamentos') >= 0
          || url.indexOf('materiais-medicos') >= 0 || url.indexOf('regioes') >= 0 || url.indexOf('unidade-saude') >= 0) {
          this.renderer.setAttribute(this.el.nativeElement, 'id', 'inside')
          this.renderer.addClass(this.el.nativeElement, 'edit')
        } else if (url.indexOf('notificacao') >= 0 || url.indexOf('investigar') >= 0) {
          this.renderer.setAttribute(this.el.nativeElement, 'id', 'inside')
          this.renderer.removeClass(this.el.nativeElement, 'edit')
        }
        if (url.indexOf('realiza-investigacao') >= 0) {
          this.renderer.setAttribute(this.el.nativeElement, 'id', 'inside')
          this.renderer.removeClass(this.el.nativeElement, 'edit')
        }
      }
    });
  }

}
