import { HttpErrorResponse } from '@angular/common/http'
import { ErrorHandler, Injectable, NgZone } from '@angular/core';
import { NotificationService } from './service/notification.service';
import { TypeMessage, Message } from './model/message.model';

@Injectable()
export class ApplicationErrorHandler extends ErrorHandler {

    constructor(private ns: NotificationService,
        private zone: NgZone) {
        super()
    }

    handleError(errorResponse: HttpErrorResponse | any) {
        super.handleError(errorResponse)
        if (errorResponse instanceof HttpErrorResponse) {
            if (errorResponse.status !== 0) {
                const error = errorResponse.error
                this.zone.run(() => {
                    const message: Message = this.getErrorMessage(error)
                    this.ns.notify(message.type, message.message || 'Erro desconhecido.')
                    // switch (errorResponse.status) {
                    //     case 400:
                    //         this.ns.notify(TypeMessage.ERROR, this.getErrorMessage(error) || 'Solicitação inválida.')
                    //     case 401:
                    //         break
                    //     case 403:
                    //         this.ns.notify(TypeMessage.ERROR, error.error_description || 'Acesso não autorizado.')
                    //         break
                    //     case 404:
                    //         this.ns.notify(TypeMessage.ERROR, error.error_description || 'Recurso não encontrado. Verifique o console para mais detalhes.')
                    //         break
                    // }
                })
            } else {
                this.ns.notify(TypeMessage.ERROR, 'Erro desconhecido.')
            }
        }

    }

    private getErrorMessage(error): Message {
        const errorKey: string = error.error || error.codigo
        let erroTxtAlternativo = error.descricao || error.codigo;
        console.log(errorKey)
        if(errorKey === "negocio.exception"){
          return new Message(TypeMessage.ERROR, erroTxtAlternativo)
        }
        return messages[errorKey] || new Message(TypeMessage.ERROR, erroTxtAlternativo);
    }
}

const messages = {
    'invalid_grant': new Message(TypeMessage.ERROR, 'Login ou senha inválidos.', 'invalid_grant'),
    'user.not.found.exception': new Message(TypeMessage.ERROR, 'Este e-mail não está cadastrado no sistema', 'user.not.found.exception'),
    'email.already.registered.exception': new Message(TypeMessage.ERROR, 'Este e-mail já existe na base. Caso tenha esquecido a senha, <a routerLink="[\'/recover-password\']" href="#/recover-password">clique aqui</a> para recuperar', 'email.already.registered.exception'),
    'user.update.email.already.registered.exception': new Message(TypeMessage.ERROR, 'Este e-mail não pode ser utilizado, pois está associado a outro cadastro"', 'user.update.email.already.registered.exception'),
    'user.invalid.token.exception': new Message(TypeMessage.ERROR, 'Token inválido'),
    'user.update.wrong.password': new Message(TypeMessage.ERROR, 'Senha atual incorreta'),
    'notification.null.exception': new Message(TypeMessage.ERROR, 'Não foram informados todos os campos obrigatórios'),
    'erro.inesperado': new Message(TypeMessage.ERROR, 'Ocorreu um erro inesperado. Tente novamente mais tarde.'),
    'sector.bed.not.found.exception': new Message(TypeMessage.ERROR, 'sector.bed.not.found.exception'),
    'region.is.already.with.name': new Message(TypeMessage.ERROR, 'Região Administrativa já existente'),
    'incident.notification.not.found.exception': new Message(TypeMessage.ERROR, 'incident.notification.not.found.exception'),
    'notification.cant.deleted.investigation.started': new Message(TypeMessage.ERROR, 'Notificação não pode ser removida. Investigação em curso.')
}
