import { HttpInterceptor, HttpEvent, HttpRequest, HttpHandler } from "@angular/common/http";
import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { LoginService } from "../service/login.service";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    
    constructor(private loginService: LoginService){}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if(this.loginService.isLoggedIn() && !request.url.includes("oauth/token")) {
            const authRequest = request.clone({
                setHeaders:{'Authorization': `Bearer ${this.loginService.usuario.token.access_token}`}
            })
            return next.handle(authRequest)
        } else {
            return next.handle(request)
        }
    }

}