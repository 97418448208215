import { EventEmitter } from "@angular/core";
import { Message, TypeMessage } from "../model/message.model";


export class NotificationService {
    notifier = new EventEmitter<Message>()

    notify(type: TypeMessage, msg: string) {
        const message: Message = new Message(type, msg)
        this.notifier.emit(message)
    }
}