import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {CategoriaProfissional} from '../model/categoria-profissional.model';
import {Observable} from 'rxjs';
import {SIGIH_API} from '../app.api';
import {Hospital} from '../model/hospital.model';
import {
  Setor,
  Lotacao,
  TiposDeSetor,
  TiposUnidadeSaude,
} from '../model/setor.model';
import {Leito} from '../model/leito.model';
import {TipoEnvolvido} from '../model/tipo-envolvido.model';
import {TipoTrabalhadorTerceirizado} from '../model/tipo-trabalhador-terceirizado.model';
import {TipoIncidente} from '../model/tipo-incidente.model';
import {TipoAssistencia} from '../model/tipo-assistencia.model';
import {Incidente} from '../model/Incidente.model';
import {TipoDano} from '../model/tipo-dano.model';
import {TipoProblemaSangue} from '../model/tipo-problema-sangue.model';
import {TipoMaterialHospitalar} from '../model/tipo-material-hospitalar.model';
import {Exportacao} from '../model/exportacao.model';
import Paciente from '../model/paciente.model';

@Injectable()
export class SetoresService {
  constructor(private http: HttpClient) {
  }

  findSetores(setorId: string, inativos: string): Observable<Setor[]> {
    let params = new HttpParams();

    if (setorId) {
      params = params.append('sectorId', '' + setorId);
    }

    if (
      inativos != null &&
      inativos != undefined &&
      (inativos + '').length > 0
    ) {
      params = params.append('inativos', '' + inativos);
    }

    return this.http.get<Setor[]>(`${SIGIH_API}/sectors/`, {params: params});
  }

  findByIdHospital(id: string): Observable<any> {
    return this.http.get<any>(`${SIGIH_API}/sectors/hospital/${id}`);
  }

  findLeitos(setorId: number, inativos: Boolean): Observable<Leito[]> {
    let params = new HttpParams();

    if (inativos != null && inativos != undefined) {
      params = params.append('inativos', '' + inativos);
    }

    return this.http.get<Leito[]>(`${SIGIH_API}/beds/${setorId}`, {params});
  }

  isLeitoUsed(setor: Setor, leito: Leito): Observable<boolean> {
    return this.http.get<boolean>(
      `${SIGIH_API}/beds/${setor.id}/${leito.id}/used/notification`
    );
  }

  isSetorUsedNotification(setor: Setor): Observable<boolean> {
    return this.http.get<boolean>(
      `${SIGIH_API}/sectors/${setor.id}/used/notification`
    );
  }

  isSetorUsed(setor: Setor): Observable<boolean> {
    return this.http.get<boolean>(`${SIGIH_API}/sectors/${setor.id}/used`);
  }

  save(setor: Setor): Observable<Setor> {
    if (setor.id) {
      return this.http.put<Setor>(`${SIGIH_API}/sectors`, setor);
    } else {
      return this.http.post<Setor>(`${SIGIH_API}/sectors`, setor);
    }
  }

  delete(setor: Setor): Observable<any> {
    return this.http.delete(`${SIGIH_API}/sectors/${setor.id}`);
  }

  findSectorTypes(): Observable<TiposDeSetor[]> {
    return this.http.get<TiposDeSetor[]>(`${SIGIH_API}/tipo_setor`);
  }

  findUnidadeSaudeTypes(): Observable<TiposUnidadeSaude[]> {
    return this.http.get<TiposUnidadeSaude[]>(
      `${SIGIH_API}/tipo_unidade_saude`
    );
  }

  getAllSetores(): Observable<Setor[]> {
    return this.http.get<Setor[]>(`${SIGIH_API}/sectors/all`);
  }

  findById(id: number): Observable<Setor> {
    return this.http.get<Setor>(`${SIGIH_API}/sectors/${id}`);
  }

  getSetoresPadroes(id: number): Observable<Setor[]> {
    return this.http.get<Setor[]>(`${SIGIH_API}/sectors/setores-padroes/${id}`);
  }
}
