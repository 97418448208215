import { EnumPerfilUsuario } from "./usuario.model"

class Perfil {
    id: number
    nome: string
    grantPerfil?: EnumPerfilUsuario
    grantPerfilName?: string
    hierarquia: number
}

export { Perfil }