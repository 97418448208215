import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { SIGIH_API } from "../app.api";
import { IndicadorDto, IndicadoresResponseDto } from "../model/indicador.model";
import { EnumPeriodo, EnumPeriodoRequest } from "../model/periodo.model";

@Injectable()
export class IndicadorService {

    constructor(private http: HttpClient) { }

    getIndicadores(idHospital: number, setor: number, ano: number, periodoNome: string, periodo: number): Observable<IndicadoresResponseDto> {
        const nomeDoPeriodo = EnumPeriodoRequest[periodoNome]
        let url = `${SIGIH_API}/indicator?ano=${ano}&nomePeriodo=${nomeDoPeriodo}`;

        if(idHospital){
            url += `&idHospital=${idHospital}`;
        }

        if(setor){
            url += `&setor=${setor}`;
        }

        if (periodo) {
            url += `&periodo=${periodo}`;
        }

        return this.http.get<IndicadoresResponseDto>(url)
    }

    getIndicadorFiltro(
      dataAntes,
      dataDepois,
      hospitalId,
      regiaoAdministrativaId,
      regiaoSaudeId,
      setorId,
      tiposUnidadeSaudeId
    ): Observable<any>{
      let params = new HttpParams();
      if(dataAntes){
        params = params.append('dataAntes', dataAntes.toString());
      }
      if(dataDepois){
        params = params.append('dataDepois', dataDepois.toString());
      }
      if(hospitalId){
        params = params.append('hospitalId', hospitalId.toString());
      }
      if(regiaoAdministrativaId){
        params = params.append('regiaoAdministrativaId', regiaoAdministrativaId.toString());
      }
      if(regiaoSaudeId){
        params = params.append('regiaoSaudeId', regiaoSaudeId.toString());
      }
      if(setorId){
        params = params.append('setorId', setorId.toString());
      }
      if(tiposUnidadeSaudeId){
        params = params.append('tiposUnidadeSaudeId', tiposUnidadeSaudeId.toString());
      }
      return this.http.get<any>(`${SIGIH_API}/indicator/findByFilter`, {params: params})
    }
}
