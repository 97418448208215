import { Usuario, EnumPerfilUsuario } from "../model/usuario.model";
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from "@angular/common/http";
import { Observable } from "rxjs";
import { SIGIH_API } from "../app.api";
import { tap } from 'rxjs/operators';
import { LoginService } from "./login.service";
import { PerfilAcessoUsuario } from "../model/perfil-acesso-usuario.model";
import { Perfil } from "../model/perfil.model";

@Injectable()
export class ManageUserService {
    getById(id: string):Observable<HttpResponse<Usuario>> {
        return this.http.get<Usuario>(`${SIGIH_API}/manage/users/${id}`, {observe:'response', responseType: 'json'});
    }

    constructor(private http: HttpClient,
                private loginService: LoginService) {}

    disabledUser(id:string):Observable<Usuario>{
        return this.http.put<Usuario>(`${SIGIH_API}/manage/users/${id}/disable`, {});
    }

    salvarUsuario(user:Usuario):Observable<Usuario>{
        let update = user.id != null

        if(update){
            return this.http.put<Usuario>(`${SIGIH_API}/manage/users/${user.id}`, user);
        }else{
            return this.http.post<Usuario>(`${SIGIH_API}/manage/users`, user);
        }
    }

    resetPasswordUsuario(idUsuario:string):Observable<any>{
        return this.http.put<JSON>(`${SIGIH_API}/manage/users/${idUsuario}/reset-password`, {});
    }

    findPerfilsPorHierarquia():Observable<Perfil[]>{
        return this.http.get<Perfil[]>(`${SIGIH_API}/perfisusuario`, {});
    }

    findByFilter(nome: string, email: string, telefone: string,
            hospitalId: number, perfilId: number, usuarioSES: boolean, 
            ativo: boolean, page: number, sortBy: string, sortMode:string):Observable<Usuario[]>{

        let map = new Map<string, string>();


        let params = new HttpParams();
        if(hospitalId){
            params = params.append("hospitalId", "" + hospitalId);
        }

        if(perfilId){
            params = params.append("perfilId", "" + perfilId);
        }

        if(nome){
            params = params.append("nome", nome);
        }
        
        if(telefone){
            params = params.append("telefone", telefone);
        }
        
        if(email){
            params = params.append("email", email);
        }

        if(page){
            params = params.append("page", "" + page);
        }

        if(sortBy){
            params = params.append("sortBy", sortBy);
        }

        if(sortMode){
            params = params.append("sortMode", sortMode);
        }

        if(usuarioSES != null && usuarioSES != undefined && (usuarioSES + "").length > 0 ){
            params = params.append("usuarioSES", "" + usuarioSES);
        }

        if(ativo != null && ativo != undefined && (ativo + "").length > 0 ){
            params = params.append("ativo", "" + ativo);
        }

        return this.http.get<Usuario[]>(`${SIGIH_API}/manage/users/`, {params});
    }
}