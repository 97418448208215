export interface TipoIncidente {
    id: number
    nome: string
}

export enum TiposIncidente {
    NotificacaoSimplificada = 1,
    IncidentesAssistenciais,
    Farmacovigilancia,
    TecnovigilanciaEquipamentos,
    TecnovigilanciaMateriais,
    Hemovigilancia
}