import { Component, OnInit } from '@angular/core';
import { UsuarioService } from 'src/app/service/usuario.service';
import { NgForm } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'sig-send-email',
  templateUrl: './send-email.component.html'
})
export class SendEmailComponent implements OnInit {

  constructor(private usuarioService: UsuarioService,
    private spinner: NgxSpinnerService) { }

  etapa: number
  email: string

  ngOnInit() {
    this.etapa = 1
  }

  recuperarSenha(form: NgForm) {
    this.spinner.show()
    if (form.valid) {
      this.email = form.value.email
      this.usuarioService.recoveryPassword(form.value.email).subscribe(email => {
        this.etapa = 2
        this.spinner.hide()
      })
    } else {
      this.spinner.hide()
    }
  }

  reenviar() {
    this.spinner.show()
    this.usuarioService.recoveryPassword(this.email).subscribe(email => {
      this.etapa = 2
      this.spinner.hide()
    })
  }

}
