import {TipoAssistencia} from './tipo-assistencia.model';
import {TipoProblemaSangue} from './tipo-problema-sangue.model';
import {TipoMaterialHospitalar} from './tipo-material-hospitalar.model';
import {Arquivo} from './arquivo.model';
import {Hospital} from './hospital.model';

class Notificacao {
  constructor() {
  }

  public id: number
  public hospitalId: number
  public setorId: number
  public leitoId: number
  public data: Date
  public hora: Date
  public tipoEnvolvidoId: number
  public tipoNotificadorId: number
  public prontuario: string
  public envolvido: string
  public nascimento: Date
  public sexo: string
  public tipoTrabalhadorTerceirizadoEnvolvidoId: number
  public tipoTrabalhadorTerceirizadoNotificadorId: number
  public tipoIncidentesNotificadorId: string
  public tipoReacaoMedicamentoIds: {}
  public outroTipoProblemaSangue: {}
  public outroTipoProblemaMedicamento: {}
  public outroTipoFarmacovigilancia: {}
  public categoriaProfissionalNotificadorId: number
  public tipoDanoIdId: boolean
  public tipoAssistenciaId: number
  public tipoProblemaSangueId: number
  public outroEnvolvido: string
  public outroNotificador: string
  public outroAssistencia: string
  public notificadorId: number
  public descricao: string
  public desejaReceber: boolean
  public houvePrejuizo: boolean
  public nome: string
  public email: string
  public telefone: string
  public protocolo: string
  public nomMedicamento: string
  public numRegistroAnivsaMs: string
  public lote: string
  public dataValidade: string
  public observacao: string
  public tiposAdministrativos: number[]
  public tiposComunicacao: number[]
}

class CreateNotification {
  constructor(
    public idSetor: number,
    public dataIncidente: string,
    public idLeito?: number,
    public horaIncidente?: string,
    public id?: number,
    public hospital?: Hospital
  ) {
  }


}

class CreateNotificationPatient implements NotificationRequest {
  constructor() {
  }

  public envolvido: Patient
  public notificador: Notifier
  public tipoIncidente: string

}

class CreateNotificationFamily implements NotificationRequest {
  constructor() {
  }

  public envolvido: Family
  public notificador: Notifier
  public tipoIncidente: string

}

class CreateNotificationOutsourced implements NotificationRequest {
  constructor() {
  }

  public envolvido: Outsourced
  public notificador: Notifier
  tipoIncidente: string
}

class CreateNotificationProfissional implements NotificationRequest {
  constructor() {
  }

  public envolvido: Outsourced
  public notificador: Notifier
  tipoIncidente: string
}

class CreateNotificationProfessional implements NotificationRequest {
  constructor() {
  }

  public envolvido: Outsourced
  public notificador: Notifier
  tipoIncidente: string
}

class CreateNotificationOther implements NotificationRequest {
  constructor() {
  }

  public envolvido: Other
  public notificador: Notifier
  tipoIncidente: string
}

class CreateMaterialTechnovigilanceIncidentRequest {
  constructor() {
  }

  public registroAnvisa: string
  public fabricante: string
  public dataFabricacao: Date
  public dataValidade: Date
  public lote: string
  public tipoMaterialHospitalar: number
  public tipoProblemaMaterial: number[]
  public dano: string
  public outro: string

}

class Patient {
  constructor(
    public nome: string,
    public nascimento: string,
    public sexo: string,
    public prontuario?: string
  ) {
  }
}

class Family {
}

class Notifier {
  constructor(public tipoEnvolvido: number,
              public tipoTerceirizado?: number,
              public categoriaProfissional?: number,
              public outro?: string) {
  }
}

class Outsourced {
  constructor(public tipoTerceirizado: number) {
  }
}

class Other {
  constructor(public outro: string) {
  }
}

interface NotificationResponse {
  id: number
  protocolo: string
}

interface NotificationRequest {
  envolvido: any
  notificador: Notifier
  tipoIncidente: string
}

interface NotifierNotificationEmail {
  nome: string
  email: string
  telefone: string
  desejaReceber: boolean
}

interface NotificationGrid {
  id: number
  nomeTipoIncidente: string
  nomeSetor: string
  nomeHospital: string
  dataNotificacao: Date
  dataOcorrencia: Date
  prejuizo: boolean
  tipoDano: string
  finalizada: boolean
}

interface NotificationDetail {
  id: number
  idTipoIncidente: number
  nomeTipoIncidente: string
  idHospital: number
  nomeHospital: string
  idSetor: number
  nomeSetor: string
  idLeito: number
  nomeLeito: string
  dataOcorrencia: Date
  horaOcorrencia: Date
  idTipoEnvolvido: number
  nomeTipoEnvolvido: string
  // Paciente
  nome: string
  nascimento: Date
  prontuario: string
  sexo: string
  // Terceirizado
  idTipoTerceirizado: number
  nomeTipoTerceirizado: string
  // Outros
  descricaoOutros: string

  descricao: string
  protocolo: string

  notificador: NotifierDetail
  incidente: IncidentDetail
  administrativo: any
  comunicacao: any
}

interface NotifierDetail {
  id: number
  tipoEnvolvido: number
  nomeTipoEnvolvido: string
  tipoTerceirizado: number
  nomeTipoTerceirizado: string
  categoriaProfissional: number
  nomeCategoriaProfissional: string
  tipoIncidente: number
  outro: string
  nome: string
  email: string
  telefone: string
  desejaReceber: boolean
}

interface IncidentDetail {
  prejuizo: boolean
  tipoDano: string
  notificacaoIncidenteAssistencial: CareIncidentDetail
  notificacaoIncidenteHemovigilancia: HemovigilanceIncidentDetail
  notificacaoIncidenteFarmacovigilancia: PharmacovigilanceIncidentDetail
  notificacaoIncidenteTecnovigilanciaEquipamento: EquipmentTechnovigilanceIncidentDetail
  notificacaoIncidenteTecnovigilanciaMaterial: MaterialTechnovigilanceIncidentDetail
}

interface CareIncidentDetail {
  tipoAssistencia: TipoAssistencia[]
  outro: string
}

interface HemovigilanceIncidentDetail {
  tipoProblemaSangue: TipoProblemaSangue[]
  outro: string
}

interface EquipmentTechnovigilanceIncidentDetail {
  tipoEquipamento: any
  arquivo: Arquivo
  patrimonio: string
  serie: string
  lote: string
  tipoProblemaEquipamento: any[]
}

interface PharmacovigilanceIncidentDetail {
  farmacovigilanciaTipoReacaoMedicamento: any[]
  dataValidade: string
  lote: string
  nomMedicamento: string
  numRegistroAnivsaMs: string
  observacao: string
}

interface MaterialTechnovigilanceIncidentDetail {
  tipoMaterialHospitalar: TipoMaterialHospitalar
  arquivo: Arquivo
  registroAnvisa: string
  fabricante: string
  dataFabricacao: Date
  dataValidade: Date
  lote: string
  tipoProblemaMaterial: any[]
  outro: string
}

enum Etapa {
  PASSO_UM = 1,
  PASSO_DOIS = 2,
  PASSO_TRES = 3,
  PASSO_QUATRO = 4
}

export {
  Notificacao, CreateNotification, CreateNotificationPatient, CreateNotificationOutsourced,
  CreateNotificationOther, NotificationResponse, Patient, Notifier, Outsourced, Other,
  NotificationRequest, CreateNotificationFamily, NotifierNotificationEmail, Family, Etapa,
  NotificationGrid, NotificationDetail, CreateNotificationProfissional,
  CreateMaterialTechnovigilanceIncidentRequest, CreateNotificationProfessional
}
