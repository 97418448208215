class Message {
    constructor(public type: TypeMessage, 
                public message: string,
                public id?: string) {}
}

enum TypeMessage {
    ERROR = 'alert-danger',
    WARNING = 'alert-warning',
    INFO = 'alert-info'
}

export {Message, TypeMessage}