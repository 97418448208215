import { Component, OnInit } from '@angular/core';
import { Signin } from 'src/app/model/signin.model';
import { LoginService } from 'src/app/service/login.service';
import { NotificationService } from 'src/app/service/notification.service';
import { Router } from '@angular/router';
import { TypeMessage } from 'src/app/model/message.model';
import { NgForm } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'sig-signin',
  templateUrl: './signin.component.html'
})
export class SigninComponent implements OnInit {

  constructor(private loginService: LoginService,
    private ns: NotificationService,
    private router: Router,
    private spinner: NgxSpinnerService) { }

  ngOnInit() {
  }

  login(form: NgForm) {
    this.spinner.show()
    if (form.valid) {
      const signin: Signin = new Signin(form.value.username, form.value.password)
      this.loginService.login(signin).pipe().subscribe(token => {
        // this.ns.notify(TypeMessage.INFO, 'Seja Bem vindo')
        this.router.navigate(['/notificacao'])
        this.spinner.hide()
      })
    } else {
      this.spinner.hide()
    }
  }

}
