import { Injectable } from '@angular/core'
import { HttpClient, HttpResponse } from "@angular/common/http";
import { SIGIH_API } from '../app.api';
import { saveAs } from 'file-saver';
import { tap } from 'rxjs/operators';

@Injectable()
export class DownloadFileService {

    constructor(private http: HttpClient) {}

    downloadFile(id: number, fileName: string): void {
        this.http.get(`${SIGIH_API}/downloadFile/${id}`, {responseType: 'blob'}).subscribe( res => {
            this.download(res, fileName);
        })
    }


    private download(blobContent: Blob, fileName: string) {
        const blob = new Blob([blobContent], { type: 'application/octet-stream' });
        saveAs(blob, fileName);
    }
    
}