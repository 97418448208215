enum EnumPeriodo {
    ANUAL = 'Anual',
    QUADRIMENSTRAL = 'Quadrimenstral',
    BIMENSTRAL = 'Bimenstral',
    MENSAL = 'Mensal'
}

enum EnumPeriodoRequest {
    ANUAL = 'ano',
    QUADRIMENSTRAL = 'quadrimestre',
    BIMENSTRAL = 'bimestre',
    MENSAL = 'mes'
}

export { EnumPeriodo, EnumPeriodoRequest }