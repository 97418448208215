import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, HostListener, Injectable } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';

import { ColumnSortedEvent, SortService } from './sort.service';


@Component({
    selector: '[sortable-column]',
    templateUrl: './sortable-column.component.html'
})
export class SortableColumnComponent implements OnInit, OnDestroy {

    constructor(private sortService: SortService) { }

    @Input('sortable-column')
    columnName: string;

    @Input('sort-direction')
    sortDirection: string = '';

    private columnSortedSubscription: Subscription;

    @Output("sortable") sortable: EventEmitter<ColumnSortedEvent> = new EventEmitter<ColumnSortedEvent>();

    @HostListener('click')
    sort() {
        let columnNameVal = this.columnName;

        //this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
        if(this.sortDirection === 'asc'){
            this.sortDirection = 'desc'
        }else if(this.sortDirection === 'desc'){
            this.sortDirection = ''
            columnNameVal = ''
            //console.log('sort reset')
        }else{
            this.sortDirection = 'asc'
        }
        
        let event = { sortColumn: columnNameVal, sortDirection: this.sortDirection };

        this.sortService.columnSorted(event);
        this.sortable.next(event)
    }

    ngOnInit() {
        // subscribe to sort changes so we can react when other columns are sorted
        this.columnSortedSubscription = this.sortService.columnSorted$.subscribe(event => {
            // reset this column's sort direction to hide the sort icons
            //console.log('Sort Direction: ', this.sortDirection)
            if (this.columnName != event.sortColumn) {
                this.sortDirection = '';
            }
        });
    }

    ngOnDestroy() {
        this.columnSortedSubscription.unsubscribe();
    }
}