import { Usuario, EnumPerfilUsuario } from "../model/usuario.model";
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { SIGIH_API } from "../app.api";
import { tap } from 'rxjs/operators';
import { LoginService } from "./login.service";

@Injectable()
export class UsuarioService {

    constructor(private http: HttpClient,
                private loginService: LoginService) {}

    save(usuario: Usuario): Observable<Usuario> {
        return this.http.post<Usuario>(`${SIGIH_API}/users`, usuario)
    }

    update(usuario: Usuario): Observable<Usuario> {
        return this.http.put<Usuario>(`${SIGIH_API}/users`, usuario)
            .pipe(tap(() => {
                this.loginService.refreshToken().subscribe()
            }))
    }

    recoveryPassword(email: string): Observable<string> {
        return this.http.post<string>(`${SIGIH_API}/recuperarSenha`, email)
    }

    updatePassword(password: string, token: string): Observable<any> {
        const body = {
            senha: password, 
            token: token
        }
        return this.http.put<any>(`${SIGIH_API}/recuperarSenha`, body)
    }

    validateEmailAvailable(email: string): Observable<boolean> {
        return this.http.post<boolean>(`${SIGIH_API}/users/validate/available`, email)
    }
}