import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate } from "@angular/router";
import { Injectable } from "@angular/core";
import { LoginService } from "../service/login.service";
import { EnumPerfilUsuario } from "../model/usuario.model";
import { AuthorizeService } from "../service/authorize.service";

@Injectable()
export class RouteInvestigadorGuard implements CanActivate {

    constructor(private loginService: LoginService,
                private authorizeService: AuthorizeService) { }

    canActivate(activatedRoute: ActivatedRouteSnapshot, routerState: RouterStateSnapshot): boolean {
        return this.checkAuthentication()
    }

    checkAuthentication(): boolean {
        const loggedIn = this.loginService.isLoggedIn();
        const usuario = this.loginService.usuario;
        if (!loggedIn) {
            window.location.href = "/";
       }
        
        const checked = loggedIn &&
            (this.authorizeService.hasPerfil(EnumPerfilUsuario.GESTOR_SISTEMA) ||
                this.authorizeService.hasPerfil(EnumPerfilUsuario.GESTOR_HOSPITAL) ||
                this.authorizeService.hasPerfil(EnumPerfilUsuario.GESTOR_NUCLEO) ||
                this.authorizeService.hasPerfil(EnumPerfilUsuario.INVESTIGADOR) ||
                this.authorizeService.hasPerfil(EnumPerfilUsuario.INVESTIGADOR_AUXILIAR)
            );
        console.log("check Investigador", checked);
        return checked
    }

}