import { Hospital } from "./hospital.model";
import { Perfil } from "./perfil.model";

class PerfilAcessoUsuario {

    constructor() { }

    public id?: number
    public hospital: Hospital
    public perfil: Perfil
   
}

export { PerfilAcessoUsuario }