import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'sig-invalid-token',
  templateUrl: './invalid-token.component.html'
})
export class InvalidTokenComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
