import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { SIGIH_API, SIGIH_AUTH } from "../app.api";

@Injectable()
export class VersionService {

    constructor(private http: HttpClient) { }


    getAuthVersion(): Observable<Version> {
        return this.http.get<Version>(`${SIGIH_AUTH}/version`);
    }

    getRestVersion(): Observable<Version> {
        return this.http.get<Version>(`${SIGIH_API}/version`);
    }

    getInfraJavaVersion(): Observable<Version> {
        return this.http.get<Version>(`${SIGIH_API}/version/infra-java`);
    }

}