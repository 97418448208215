import { Component, TemplateRef } from '@angular/core';
import { environment } from 'src/environments/environment';
import { LoginService } from './service/login.service';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { VersionService } from './service/version.service';
import { NotificationService } from './service/notification.service';
import { TypeMessage } from './model/message.model';
import { AuthorizeService } from './service/authorize.service';
import { Router } from '@angular/router';
import { Hospital } from './model/hospital.model';
import { Perfil } from './model/perfil.model';
import { EnumPerfilUsuario } from './model/usuario.model';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'sig-root',
  templateUrl: './app.component.html'
})
export class AppComponent {
  title = 'app-web';

  modalIdle = 'idleUserTimeout';
  modalRef: BsModalRef;
  modalCtxEstabelecimento: BsModalRef;
  authVersion: string = "";
  restVersion: string = "";
  infraJavaVersion: string = "";
  webVersion: string = "";

  idHospitalChgCtx: number;
  idPerfilChgCtx: number;
  hospitalsCtx: Hospital[];
  perfisCtx: Perfil[];

  constructor(private loginService: LoginService, 
    private authorizeSevice: AuthorizeService, 
    private ngxSmartModalService: NgxSmartModalService,
    private modalService: BsModalService,
    private spinner: NgxSpinnerService,
    private ns: NotificationService,
    private router: Router,
    private versionService: VersionService) {
    
    this.loginService.onTimeoutCounter().subscribe(num =>{
      
      let timeoutConfig = this.loginService.getTimeoutLimit();
      let offset = (timeoutConfig - num);
      if(timeoutConfig == offset){
        offset = 0;
      }
      
      ngxSmartModalService.setModalData(offset, this.modalIdle, true);
      if(!ngxSmartModalService.getModal(this.modalIdle).isVisible()){
        ngxSmartModalService.getModal(this.modalIdle).open();
      }

    })

    this.loginService.onRefreshTokenExpired().subscribe(bol=>{
        if(bol){
          ngxSmartModalService.setModalData(-1, this.modalIdle, true);
        if(!ngxSmartModalService.getModal(this.modalIdle).isVisible()){
          ngxSmartModalService.getModal(this.modalIdle).open();
        }
      }
    })

  }

  getAmbiente(){
    let name = window.location.hostname;

    if(name.match('.*(treino-)|(10.233.66.107).*')){
      return "treinamento";
    }else if(name.match('.*(hmg-)|(10.233.66.106).*')){
      return "teste";
    }else if(name.match('.*(localhost).*')){
      return "desenvolvimento";
    }
    return "";
  }

  showBoxAtencao(){
    return window.location.hostname.match('.*(treino-)|(hmg-)|(localhost)|(10.233.66.107)|(10.233.66.106).*')
  }

  isLoggedIn(){
    return this.loginService.isLoggedIn();
  }

  idleLogout(){
    this.loginService.logout();
    this.ngxSmartModalService.close(this.modalIdle);
  }

  resetIdle(){
    this.ngxSmartModalService.close(this.modalIdle);
    this.loginService.stopCountTimeout();
  }

  trocarEstabelecimento(template: TemplateRef<any>){
    this.idPerfilChgCtx = this.authorizeSevice.getPerfilCtx().id;
    
    this.perfisCtx = Array();
    this.loginService.usuario.perfisAcessoUsuario.forEach(paa => {
      if(this.perfisCtx.findIndex(p => p.id == paa.perfil.id) == -1){
        this.perfisCtx.push(paa.perfil);
      }
    });
    this.onChangePerfilCtx(this.idPerfilChgCtx);
    this.idHospitalChgCtx = (this.authorizeSevice.getHospitalCtx() ? this.authorizeSevice.getHospitalCtx().id : null);


    this.modalCtxEstabelecimento = this.modalService.show(template);
  }

  efetuarMundacaCtx(){
    if(!this.idPerfilChgCtx){
      this.ns.notify(TypeMessage.ERROR, 'Perfil é obrigatório');
      return;
    }

    let strgTest = '' + this.idPerfilChgCtx;

    if(EnumPerfilUsuario.NOTIFICADOR != strgTest && EnumPerfilUsuario.GESTOR_SISTEMA != strgTest && EnumPerfilUsuario.GESTOR_SEGURANCA_PACIENTE != strgTest && !this.idHospitalChgCtx){
      this.ns.notify(TypeMessage.ERROR, 'Estabelecimento de Saúde é obrigatório');
      return;
    }

    this.spinner.show();
    this.loginService.refreshTokenRequest(this.idPerfilChgCtx, this.idHospitalChgCtx).subscribe(c => {
      this.ns.notify(TypeMessage.INFO, 'Alterado perfil para ' + this.authorizeSevice.getPerfilCtx().nome);
      this.modalCtxEstabelecimento.hide();
      this.spinner.hide()
      this.router.navigate(['/notificacao'])
    });
  }
  
  cancelarCtx(){
    this.modalCtxEstabelecimento.hide();
  }

  onChangePerfilCtx(idPerfilChanged: number){
    this.idPerfilChgCtx = idPerfilChanged;
    this.idHospitalChgCtx = null;
    this.hospitalsCtx = new Array();
    this.loginService.usuario.perfisAcessoUsuario
      .filter(paa => paa.hospital && paa.hospital.id)
      .filter(paa => paa.perfil.id == this.idPerfilChgCtx).forEach(paa => {
      if(this.hospitalsCtx.findIndex(h => h.id == paa.hospital.id) == -1){
        this.hospitalsCtx.push(paa.hospital);
      }
    })
  }

  getPerfilAcessoCtx(){
    if(this.loginService.usuario && 
      this.loginService.usuario.perfilAcessoUsuarioCtx &&  
      (!this.authorizeSevice.hasPerfil(EnumPerfilUsuario.NOTIFICADOR) ||
      this.loginService.usuario.perfisAcessoUsuario.length > 1)){
      return this.loginService.usuario.perfilAcessoUsuarioCtx;
    }

    return null;
  }

  canChangeCtx(){
    return this.loginService.usuario.perfisAcessoUsuario.length > 1;
  }

  abrirSobreApp(template: TemplateRef<any>){

    if(this.authVersion == ""){
      this.versionService.getAuthVersion().subscribe(val => {
        this.authVersion = val.version;
      })
    }

    if(this.restVersion == ""){
      this.versionService.getRestVersion().subscribe(val => {
        this.restVersion = val.version;
      })
    }

    if(this.infraJavaVersion == ""){
      this.versionService.getInfraJavaVersion().subscribe(val => {
        this.infraJavaVersion = val.version;
      })
    }

    this.modalRef = this.modalService.show(template);

  }

  fecharSobreApp(template: TemplateRef<any>){

    this.modalRef.hide()

  }

  getVersaoEcossistema(){
    return `${environment.versaoEcossistema}`;
  }

  getVersaoAppWeb(){
    return `${environment.versaoAppWeb}`;
  }

}
