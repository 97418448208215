interface TipoDano {
    string: number
    nome: string
}

enum EnumTipoDano {
    LEVE = "L",
    MODERADO = "M",
    GRAVE = "G",
    OBITO = "O"
}

export {TipoDano, EnumTipoDano}