interface CategoriaReacaoMedicamento {
    id: number
    nome: string
}

enum EnumCategoriaReacaoMedicamento {
    ReacaoAdversa = 1,
    QueixaTecnica,
    IncidenteAdministracao,
    IncidenteDispensacao
}

export { CategoriaReacaoMedicamento, EnumCategoriaReacaoMedicamento }