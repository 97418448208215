import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { IndicadorDto } from "../model/indicador.model";
import { Injectable } from "@angular/core";
import { SIGIH_API } from "../app.api";
import { Observable } from "rxjs";
import { concatMap, tap } from "rxjs/operators";
import { saveAs } from 'file-saver';
import { FiltroInvestigacoes, FiltroInvestigacoesPacienteString } from "../model/filtro-pesquisa.model";
import { Investigacao } from "../model/investigacao.model";
import { Usuario } from "../model/usuario.model";
import { InvestigadorAuxiliar } from "../model/InvestigadorAuxiliar";
import { InvestigacaoGridResponse } from "../model/InvestigacaoGridResponse";

@Injectable()
export class InvestigacaoService {
 
    constructor(private http: HttpClient) { }

    page = 1;

    salvar(file, id) {
        return this.http.post<IndicadorDto[]>(`${SIGIH_API}/investigations/action-plan/${id}`, this.getFormData(file), { headers: this.getHeadersFormData() })
    }

    private getFormData(file) {
        let formData: FormData = new FormData()
        formData.append('file', file)
        return formData;
    }

    private getHeadersFormData() {
        let headers = new HttpHeaders();
        headers.append('Content-Type', 'multipart/form-data');
        return headers;
    }

    findInvestigations(filtro: FiltroInvestigacoesPacienteString, page: number, sortBy?: string, sortDirection?:string, countTotal?:boolean, semVinculoInvestigacao?: boolean, ignoreIds?:number[]): Observable<InvestigacaoGridResponse> {
        
        
        let params = new HttpParams();
        params = params.append("page", "" + page);
        
        if(filtro.dataInicial && filtro.dataFinal != undefined){
            const dataInicial = filtro.dataInicial.toISOString().substring(0, 10)
            params = params.append("dataInicial", dataInicial);
        }

        if(filtro.dataFinal && filtro.dataFinal != undefined){
            const dataFinal = filtro.dataFinal.toISOString().substring(0, 10)
            params = params.append("dataFinal", dataFinal)
        }
        
        if(filtro.tipoNotificacao != null && filtro.tipoNotificacao != undefined){
            params = params.append("tipoNotificacao", "" + filtro.tipoNotificacao);
        }

        if(filtro.paciente != null && filtro.paciente != undefined){
            params = params.append("nome", filtro.paciente);
        }

        if(filtro.protocolo != null && filtro.protocolo != undefined){
            params = params.append("protocolo", filtro.protocolo);
        }


        if(filtro.situacao != null && filtro.situacao != undefined && filtro.situacao.trim().length > 0){
            params = params.append("situacao", filtro.situacao);
        }

        if(filtro.tipoDano != null && filtro.tipoDano != undefined){
            params = params.append("dano", filtro.tipoDano);
        }

        if(filtro.setor != null && filtro.setor != undefined){
            params = params.append("setor", "" + filtro.setor);
        }
        
        if(filtro.hospital != null && filtro.hospital != undefined){
            params = params.append("hospital", "" + filtro.hospital);
        }

        if(countTotal){
            params = params.append("countTotal", "" + countTotal);
        }

        if(sortBy){
            params = params.append("sortBy",  sortBy);
        }

        if(sortDirection){
            params = params.append("sortDirection", sortDirection);
        }

        if(semVinculoInvestigacao && semVinculoInvestigacao != undefined){
            params = params.append("semVinculoNotificacao", "" + semVinculoInvestigacao);
        }

        if(ignoreIds && ignoreIds != undefined && ignoreIds.length > 0){
            ignoreIds.forEach(id => {
                params = params.append("ignoreIds", "" + id);
            })

        }

        return this.http.get<InvestigacaoGridResponse>(`${SIGIH_API}/investigations`,{params: params})
    }

    findInvestigationsIniciadas(page:number, sortBy?: string, sortDirection?:string, countTotal?:boolean): Observable<InvestigacaoGridResponse> {

        let params = new HttpParams();
        params = params.append("page", "" + page);

        if(countTotal){
            params = params.append("countTotal", "" + countTotal);
        }

        if(sortBy){
            params = params.append("sortBy",  sortBy);
        }

        if(sortDirection){
            params = params.append("sortDirection", sortDirection);
        }

        return this.http.get<InvestigacaoGridResponse>(`${SIGIH_API}/investigations/ainiciar`, {params: params})
    }

    findInvestigators(usuario: Usuario, idNotificacao:number): Observable<any> {
        let nome = usuario.nome || '';
        let email = usuario.email || '';

        return this.http.get<any>(`${SIGIH_API}/investigators/available?idNotificacao=${idNotificacao}&nome=${nome}&email=${email}`)
    }

    findAuxiliaryByNotificacao(idNotificacao:number): Observable<InvestigadorAuxiliar[]> {
        return this.http.get<InvestigadorAuxiliar[]>(`${SIGIH_API}/investigators/auxiliary/notification/${idNotificacao}`)
    }

    savlarAuxiliares(idNotificacao:number, auxiliares: InvestigadorAuxiliar[]) {
        return this.http.put<any>(`${SIGIH_API}/investigators/auxiliary/notification/${idNotificacao}`, auxiliares);
    }

    createNotificacao(idNotificacao, idUsuarios: number[], readOnly: boolean) {
        return this.http.post<any>(`${SIGIH_API}/investigations`, { idNotificacao, idUsuarios, readOnly });
    }

    updateNotificacao(investigationId, idsFatorContribuinte, numDocumentoInterno, numDocumentoNotivisaVigimed, idNotificacao, idsUsuario?) {
        return this.http.put<any>(`${SIGIH_API}/investigations/${investigationId}`, {
            idsFatorContribuinte,
            idsUsuario,
            idNotificacao,
            numDocumentoInterno,
            numDocumentoNotivisaVigimed
        });
    }

    updateNotificacaoPrejuizo(id, dto) {
        return this.http.post<any>(`${SIGIH_API}/investigations/${id}/dano`, dto);
    }

    getFatores() {
        return this.http.get<any>(`${SIGIH_API}/contributingTypes`)
    }

    getFatoresInvestifacao(idInvestigacao: number) {
        return this.http.get<any>(`${SIGIH_API}/fatores/${idInvestigacao}`)
    }

    downloadPlanoAcao(id: number) {
        return this.http.get(`${SIGIH_API}/investigations/action-plan/${id}/template`, {
            responseType: "blob",
            headers: new HttpHeaders().append("Content-Type", "application/json")
        });
    }

    downloadPlanoAcaoPreenchido(id: number) {
        return this.http.get(`${SIGIH_API}/investigations/action-plan/${id}/action_plans.docx`, {
            responseType: "blob",
            headers: new HttpHeaders().append("Content-Type", "application/json")
        });
    }

    getPrejuizo(id: number) {
        return this.http.get<Investigacao>(`${SIGIH_API}/investigations/${id}/prejuizo`);
    }

    getFileName(id: number) {
        return this.http.get(`${SIGIH_API}/investigations/action-plan/${id}/nome`);
    }

    findByUserName() {
        return this.http.get(`${SIGIH_API}/investigations/investigacao-by-username`);
    }

    findById(id) {
        return this.http.get<Investigacao>(`${SIGIH_API}/investigations/${id}`);
    }

    findQtdInvestigations(filtro) {
        const dataInicial = filtro.dataInicial.toISOString().substring(0, 10)
        const dataFinal = filtro.dataFinal.toISOString().substring(0, 10)
        const tipoNotificacao = filtro.tipoNotificacao !== undefined ? filtro.tipoNotificacao : ''
        const paciente = filtro.paciente && filtro.paciente !== undefined ? filtro.paciente : ''
        return this.http.get<any>(`${SIGIH_API}/investigations/qtd-filtrado?dataInicial=${dataInicial}&dataFinal=${dataFinal}&tipoNotificacao=${tipoNotificacao}&nome=${paciente}&investigadorAux=${filtro.isInvestAUx}`)
    }

    forceDeleteNotificacaoInvetigacao(idNotificacao: number): Observable<any>{
        return this.http.delete<any>(`${SIGIH_API}/investigations/${idNotificacao}/force-delete`);
    }
}