import { Injectable } from "@angular/core";
import {
  CreateNotification,
  NotificationResponse,
  NotificationRequest,
  CreateNotificationOutsourced,
  CreateNotificationPatient,
  CreateNotificationFamily,
  CreateNotificationOther,
  NotifierNotificationEmail,
  NotificationGrid,
  NotificationDetail,
  Notificacao,
  CreateNotificationProfissional,
} from "../model/notificacao.model";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { SIGIH_API } from "../app.api";
import { Observable } from "rxjs";
import { FiltroGerirNotificacoes } from "../model/filtro-pesquisa.model";
import { Exportacao } from "../model/exportacao.model";

@Injectable()
export class NotificacaoService {
  constructor(private http: HttpClient) {}

  saveStepOne(
    createNotification: CreateNotification
  ): Observable<NotificationResponse> {
    return this.http.post<NotificationResponse>(
      `${SIGIH_API}/notifications`,
      createNotification
    );
  }

  saveStepTwo(
    id: number,
    notification: NotificationRequest
  ): Observable<NotificationResponse> {
    if (notification instanceof CreateNotificationPatient) {
      return this.http.post<NotificationResponse>(
        `${SIGIH_API}/notifications/paciente/${id}`,
        notification
      );
    } else if (notification instanceof CreateNotificationFamily) {
      return this.http.post<NotificationResponse>(
        `${SIGIH_API}/notifications/familiar/${id}`,
        notification
      );
    } else if (notification instanceof CreateNotificationOutsourced) {
      return this.http.post<NotificationResponse>(
        `${SIGIH_API}/notifications/terceirizado/${id}`,
        notification
      );
    } else if (notification instanceof CreateNotificationOther) {
      return this.http.post<NotificationResponse>(
        `${SIGIH_API}/notifications/outro/${id}`,
        notification
      );
    } else if (notification instanceof CreateNotificationProfissional) {
      return this.http.post<NotificationResponse>(
        `${SIGIH_API}/notifications/profissional/${id}`,
        notification
      );
    }
  }

  saveStepThree(args): Observable<NotificationResponse> {
    return this.http.patch<NotificationResponse>(
      `${SIGIH_API}/notifications/${args.id}`,
      { descricao: args.descricao }
    );
  }

  saveCareType(notificacao: Notificacao): Observable<NotificationResponse> {
    let { id, tipoAssistenciaId, tipoDanoIdId, outroAssistencia, descricao } =
      notificacao;
    return this.http.post<NotificationResponse>(
      `${SIGIH_API}/incidents/care/${id}`,
      {
        tipoAssistencia: tipoAssistenciaId,
        dano: tipoDanoIdId,
        outro: outroAssistencia,
        descricao: descricao,
      }
    );
  }

  saveAdministrativoType(
    notificacao: Notificacao
  ): Observable<NotificationResponse> {
    let { id, tiposAdministrativos, tipoDanoIdId, descricao } = notificacao;
    console.log(notificacao);
    return this.http.post<NotificationResponse>(
      `${SIGIH_API}/incidents/administrativo/${id}`,
      {
        tipoAdministrativo: tiposAdministrativos,
        dano: tipoDanoIdId,
        descricao: descricao,
      }
    );
  }

  saveComunicacaoType(
    notificacao: Notificacao
  ): Observable<NotificationResponse> {
    let { id, tiposComunicacao, tipoDanoIdId, descricao } = notificacao;
    console.log(notificacao);
    return this.http.post<NotificationResponse>(
      `${SIGIH_API}/incidents/comunicacao/${id}`,
      {
        tipoComunicacao: tiposComunicacao,
        dano: tipoDanoIdId,
        descricao: descricao,
      }
    );
  }

  savebloodProblemType(
    notificacao: Notificacao
  ): Observable<NotificationResponse> {
    let { id, tipoProblemaSangueId, tipoDanoIdId, outroTipoProblemaSangue } =
      notificacao;
    return this.http.post<NotificationResponse>(
      `${SIGIH_API}/incidents/hemovigilance/${id}`,
      {
        tipoProblemaSangue: tipoProblemaSangueId,
        dano: tipoDanoIdId,
        outro: outroTipoProblemaSangue,
      }
    );
  }

  saveStepFour(
    id: number,
    notificador: NotifierNotificationEmail
  ): Observable<NotificationResponse> {
    return this.http.patch<NotificationResponse>(
      `${SIGIH_API}/notifications/notifier/${id}`,
      notificador
    );
  }

  findNotifications(
    filtro: FiltroGerirNotificacoes
  ): Observable<NotificationGrid[]> {
    const dataInicial = filtro.dataInicial.toISOString().substring(0, 10);
    const dataFinal = filtro.dataFinal.toISOString().substring(0, 10);
    const tipoNotificacao =
      filtro.tipoNotificacao !== undefined ? filtro.tipoNotificacao : "";
    return this.http.get<NotificationGrid[]>(
      `${SIGIH_API}/notifications?dataInicial=${dataInicial}&dataFinal=${dataFinal}&tipoNotificacao=${tipoNotificacao}`
    );
  }

  findNotification(id: number): Observable<NotificationDetail> {
    return this.http.get<NotificationDetail>(
      `${SIGIH_API}/notifications/${id}`
    );
  }

  removeNotification(id: number): Observable<any> {
    return this.http.delete<any>(`${SIGIH_API}/notifications/${id}`);
  }

  saveDrugReaction(
    notificacao: Notificacao,
    tiposReacao: []
  ): Observable<NotificationResponse> {
    let {
      id,
      tipoDanoIdId,
      outroTipoProblemaMedicamento,
      nomMedicamento,
      numRegistroAnivsaMs,
      observacao,
      lote,
      dataValidade,
    } = notificacao;
    return this.http.post<NotificationResponse>(
      `${SIGIH_API}/incidents/pharmacovigilance/${id}`,
      {
        tipoReacaoMedicamento: tiposReacao,
        dano: tipoDanoIdId,
        outro: outroTipoProblemaMedicamento,
        nomMedicamento,
        numRegistroAnivsaMs,
        observacao,
        lote,
        dataValidade,
      }
    );
  }

  saveTechnovigilanceMaterial(args): Observable<NotificationResponse> {
    let argsMounted = {
      registroAnvisa: args.registroAnvisa,
      fabricante: args.fabricante,
      dataFabricacao: args.dataFabricacao
        ? args.dataFabricacao.toLocaleDateString("pt-br", {
            timeZone: "America/Sao_Paulo",
          })
        : null,
      dataValidade: args.dataValidade
        ? args.dataValidade.toLocaleDateString("pt-br", {
            timeZone: "America/Sao_Paulo",
          })
        : null,
      lote: args.lote,
      tipoMaterialHospitalar: args.tipoM.id,
      tipoProblemaMaterial: args.tipoProblemaMaterialHospitalar,
      dano: args.tipoDanoId,
      outro: args.outro || null,
      file: args.file,
    };
    return this.http.post<NotificationResponse>(
      `${SIGIH_API}/incidents/technovigilance/material/${args.id}`,
      this.getFormData(argsMounted),
      { headers: this.getHeadersFormData() }
    );
  }

  saveTechnovigilancEquipamentos(args): Observable<NotificationResponse> {
    let argsMounted = {
      patrimonio: args.patrimonio,
      serie: args.serie,
      lote: args.lote,
      tipoEquipamento: args.tipoE.id,
      tipoProblemaEquipamento: args.tipoProblemaEquipamento,
      dano: args.tipoDanoId,
      file: args.file,
    };
    return this.http.post<NotificationResponse>(
      `${SIGIH_API}/incidents/technovigilance/equipment/${args.id}`,
      this.getFormData(argsMounted),
      { headers: this.getHeadersFormData() }
    );
  }

  gerarCsv(exportacao: Exportacao) {
    return this.http.post(`${SIGIH_API}/export/csv`, exportacao, {
      headers: this.getHeadersFormData(),
    });
  }

  downloadCsv(id: number) {
    return this.http.get(`${SIGIH_API}/export/csv/${id}`, {
      responseType: "blob",
    });
  }

  getFile(id: number) {
    return this.http.get(`${SIGIH_API}/export/csv/${id}`, {
      responseType: "blob",
    });
  }

  private getFormData(args) {
    let formData: FormData = new FormData();
    formData.append("jsonData", JSON.stringify(args));
    if (args.file) {
      formData.append("file", args.file);
    }
    return formData;
  }

  private getHeadersFormData() {
    let headers = new HttpHeaders();
    headers.append("Content-Type", "multipart/form-data");
    return headers;
  }
}
