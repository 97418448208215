import { Component, OnInit } from '@angular/core';
import { Usuario } from 'src/app/model/usuario.model';
import { Radio } from 'src/app/shared/radio/radio.model';
import { UsuarioService } from 'src/app/service/usuario.service';
import { CategoriaProfissional } from 'src/app/model/categoria-profissional.model';
import { ComboService } from 'src/app/service/combo.service';
import { Hospital } from 'src/app/model/hospital.model';
import { Lotacao, Setor } from 'src/app/model/setor.model';
import { NotificationService } from 'src/app/service/notification.service';
import { TypeMessage } from 'src/app/model/message.model';
import { Signin } from 'src/app/model/signin.model';
import { LoginService } from 'src/app/service/login.service';
import { switchMap, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { NgForm, NgModel } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { UnidadeSaude } from 'src/app/model/unidade-saude.model';

@Component({
  selector: 'sig-signup',
  templateUrl: './signup.component.html'
})
export class SignupComponent implements OnInit {

  usuario: Usuario
  etapa: number
  profissionalOptions: Radio[] = [
    { label: 'Sim', value: true },
    { label: 'Não', value: false }
  ]
  categoriasProfissional: CategoriaProfissional[]
  hospitais: UnidadeSaude[]
  setores: Setor[]

  constructor(private usuarioService: UsuarioService,
    private comboService: ComboService,
    private ns: NotificationService,
    private loginService: LoginService,
    private router: Router,
    private spinner: NgxSpinnerService) { }

  ngOnInit() {
    this.spinner.show()
    this.comboService.getCategoriasProfissional().pipe(
      tap(categorias => this.categoriasProfissional = categorias),
      switchMap(categorias => this.comboService.getHospitais())
    ).subscribe(hospitais => {
      this.hospitais = hospitais
      this.etapa = 1
      this.spinner.hide()
    })
  }

  continuar(form: NgForm) {
    this.spinner.show()
    if (form.valid) {
      this.usuarioService.validateEmailAvailable(form.value.email).subscribe(isValid => {
        this.usuario = new Usuario()
        this.usuario.nome = form.value.nome
        this.usuario.email = form.value.email
        this.usuario.telefone = form.value.telefone
        this.etapa = 2
        this.spinner.hide()
      })
    } else {
      this.spinner.hide()
    }

  }

  finalizar(form: any) {
    this.spinner.show()
    if (form.valid) {
      this.usuario.senha = form.value.senha
      this.usuario.confirmaSenha = form.value.confirmaSenha
      this.usuario.profissionalAreaSaude = form.value.profissionalAreaSaude
      if (form.value.profissionalAreaSaude && this.validaDadosProfissional(form.value)) {
        this.usuario.idCategoriaProfissional = form.value.categoriaProfissional
        this.usuario.idHospital = form.value.hospital
        this.usuario.idSetor = form.value.lotacao
      }

      this.usuarioService.save(this.usuario).pipe(
        tap(() => this.ns.notify(TypeMessage.INFO, `Usuário ${this.usuario.nome} cadastrado com sucesso`)),
        switchMap(() => {
          const signin: Signin = new Signin(this.usuario.email, this.usuario.senha)
          return this.loginService.login(signin)
        })
      ).subscribe(() => {
        this.router.navigate(['/notificacao'])
        this.spinner.hide()
      })
    } else {
      this.spinner.hide()
    }
  }

  getSetores(hospitalId: number) {
    this.spinner.show()
    if (hospitalId) {
      this.setores = this.hospitais.find(
        (hospital) => hospital.id == hospitalId
      ).relSetoresList;
      this.spinner.hide();
    }  else {
      this.setores = []
      this.spinner.hide()
    }
  }

  voltar() {
    this.etapa = 1
  }

  private validaDadosProfissional(form: any): boolean {
    let validou = true
    if (!form.categoriaProfissional) {
      this.ns.notify(TypeMessage.ERROR, 'Profissional é obrigatório')
      validou = false
    }
    if (!form.hospital) {
      this.ns.notify(TypeMessage.ERROR, 'Hospital é obrigatório')
      validou = false
    }
    if (!form.lotacao) {
      this.ns.notify(TypeMessage.ERROR, 'Lotação é obrigatório')
      validou = false
    }

    return validou
  }

}
