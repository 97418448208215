import { Component, OnInit, Input, ContentChild, AfterContentInit } from '@angular/core';
import { NgModel, NgForm } from '@angular/forms';

@Component({
  selector: 'sig-input-container',
  templateUrl: './input.component.html'
})
export class InputComponent implements OnInit, AfterContentInit {

  input: any
  @Input() form: NgForm
  @Input() label: string
  @Input() errorMessage: string
  @Input() required: boolean
  @Input() stylePass: string;

  @ContentChild(NgModel) model: NgModel

  constructor() { }

  ngOnInit() {
  }

  ngAfterContentInit(): void {
    this.input = this.model
    if(this.input === undefined) {
      throw new Error('Esse componente precisa ser utilizado com uma diretiva ngModel')
    }
  }

  hasError(): boolean {
    return this.input.invalid && this.form.submitted
  }

}
