import { Component, OnInit } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { NotificationService } from '../../../service/notification.service';
import { Message, TypeMessage } from '../../../model/message.model';
import { NgxSpinnerService } from 'ngx-spinner';


@Component({
  selector: 'sig-snackbar',
  templateUrl: './snackbar.component.html',
  animations: [
    trigger('snack-visibility', [
      state('hidden', style({
        display: 'none',
        right: -315
      })),
      state('visible', style({
        opacity: 0.9,
        right: 20,
        display: 'block',
        "z-index": '9999999999999'
      })),
      transition('hidden => visible', animate('300ms 0s ease-in')),
      transition('visible => hidden', animate('300ms 0s ease-out'))
    ])
  ]
})
export class SnackbarComponent implements OnInit {

  message: Message
  snackVisibility: string = 'hidden'
  typeMessage: string

  constructor(private notificationService: NotificationService,
    private spinner: NgxSpinnerService) { }

  ngOnInit() {
    // this.notificationService.notifier.pipe(
    //   tap(message => {
    //     this.message = message
    //     this.snackVisibility = 'visible'
    //   }),
    //   switchMap(message => timer(3000))
    // ).subscribe(timer => this.snackVisibility = 'hidden')
    this.notificationService.notifier.subscribe(message => {
      this.message = message
      this.snackVisibility = 'visible'
      this.spinner.hide()
      setTimeout(() => {
        this.close();
      }, 5000)
    })
  }

  close() {
    this.snackVisibility = 'hidden'
  }

  isErrorMessage(): boolean {
    if (this.message === undefined) {
      return false
    }
    return this.message.type === TypeMessage.ERROR
  }

  isWarningMessage(): boolean {
    if (this.message === undefined) {
      return false
    }
    return this.message.type === TypeMessage.WARNING
  }

  isInfoMessage(): boolean {
    if (this.message === undefined) {
      return false
    }
    return this.message.type === TypeMessage.INFO
  }
}
