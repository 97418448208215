import { Component, OnInit } from '@angular/core';
import { LoginService } from 'src/app/service/login.service';
import { EnumPerfilUsuario } from 'src/app/model/usuario.model';
import { AuthorizeService } from 'src/app/service/authorize.service';

@Component({
  selector: 'sig-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit {

  constructor(private loginService: LoginService,
    private authorizeService: AuthorizeService ) { }

  ngOnInit() {

  }

  
  login() {
    this.loginService.handleLogin()
  }

  logout() {
    this.loginService.logout()
  }

  isLoggedIn(): boolean {
    const loggedIn = this.loginService.isLoggedIn()

    return loggedIn
  }

  getNmUsuario(){
    let usuario = this.loginService.usuario;
    if(this.isLoggedIn() && usuario && usuario.nome){
      let partNome = usuario.nome.split(" ");
      return partNome[0];
    }

    return null;

  }

  canShowExport() {
    const usuario = this.loginService.usuario
    return this.isLoggedIn() && (this.authorizeService.hasPerfil(EnumPerfilUsuario.GESTOR_SISTEMA) ||
      this.authorizeService.hasPerfil(EnumPerfilUsuario.GESTOR_SEGURANCA_PACIENTE )||
      this.authorizeService.hasPerfil(EnumPerfilUsuario.GESTOR_HOSPITAL) ||
      this.authorizeService.hasPerfil(EnumPerfilUsuario.GESTOR_NUCLEO) ||
      this.authorizeService.hasPerfil(EnumPerfilUsuario.INVESTIGADOR))
  }

  canShowIndicadores(){
    const usuario = this.loginService.usuario
    return this.isLoggedIn() && 
      (this.authorizeService.hasPerfil(EnumPerfilUsuario.GESTOR_SISTEMA )||
      this.authorizeService.hasPerfil(EnumPerfilUsuario.GESTOR_SEGURANCA_PACIENTE )||
      this.authorizeService.hasPerfil(EnumPerfilUsuario.GESTOR_HOSPITAL )||
      this.authorizeService.hasPerfil(EnumPerfilUsuario.GESTOR_NUCLEO )||
      this.authorizeService.hasPerfil(EnumPerfilUsuario.INVESTIGADOR))
  }

  canShowInvestigacao(){
    const usuario = this.loginService.usuario
    return this.isLoggedIn() && (this.authorizeService.hasPerfil(EnumPerfilUsuario.GESTOR_SISTEMA) ||
      this.authorizeService.hasPerfil(EnumPerfilUsuario.GESTOR_HOSPITAL) ||
      this.authorizeService.hasPerfil(EnumPerfilUsuario.GESTOR_NUCLEO) ||
      this.authorizeService.hasPerfil(EnumPerfilUsuario.INVESTIGADOR) || 
      this.authorizeService.hasPerfil(EnumPerfilUsuario.INVESTIGADOR_AUXILIAR));
  }

  canShowCrud(){
    return this.isLoggedIn() && (this.authorizeService.hasPerfil(EnumPerfilUsuario.GESTOR_SISTEMA) ||
      this.authorizeService.hasPerfil(EnumPerfilUsuario.GESTOR_HOSPITAL) ||
      this.authorizeService.hasPerfil(EnumPerfilUsuario.GESTOR_NUCLEO))
  }

  canCrudLeitos(){
    return this.isLoggedIn() && (this.authorizeService.hasPerfil(EnumPerfilUsuario.GESTOR_SISTEMA) ||
      this.authorizeService.hasPerfil(EnumPerfilUsuario.GESTOR_HOSPITAL))
  }
}
