import { Component, OnInit } from '@angular/core';
import { UsuarioService } from 'src/app/service/usuario.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from 'src/app/service/notification.service';
import { TypeMessage } from 'src/app/model/message.model';
import { NgForm } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'sig-new-password',
  templateUrl: './new-password.component.html'
})
export class NewPasswordComponent implements OnInit {

  token: string

  constructor(private usuarioService: UsuarioService, 
              private route: ActivatedRoute, 
              private ns: NotificationService,
              private router: Router,
              private spinner: NgxSpinnerService) { }

  ngOnInit() {
    this.token = this.route.snapshot.params['token']
  }

  atualizarSenha(form: NgForm) {
    this.spinner.show()
    if(form.valid) {
      this.usuarioService.updatePassword(form.value.senha, this.token).subscribe(() => {
        this.ns.notify(TypeMessage.INFO, 'Senha alterada com sucesso')
        this.router.navigate(['/signin'])
        this.spinner.hide()
      })
    } else {
      this.spinner.hide()
    }
  }

}
